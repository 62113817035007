import React, { useMemo } from "react";
import { classNames } from "./common.style";
import labels from "../common/FTE.string";


const Pagination = ({ currentPage, handlePrevPageClick, pageNumbers, handlePageClick, handleNextPageClick }: {
    currentPage: number,
    handlePrevPageClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
    handleNextPageClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
    handlePageClick: (event: React.MouseEvent<HTMLAnchorElement>) => void,
    pageNumbers: number[]
}) => { 
    const memoizedPageNumbers = useMemo(() => (
        pageNumbers &&
            pageNumbers.map((index: number) => (
                <span key={index}>
                    <a
                        className={
                            currentPage === index
                                ? `${classNames.selectedPageNumbersItem}`
                                : `${classNames.pageNumbersItem}`
                        }
                        key={index}
                        id={index.toString()}
                        onClick={handlePageClick}
                    >
                        {index}
                    </a>
                </span>
            ))
    ), [currentPage, handlePageClick, pageNumbers]);

    const memoizedPrevButton = useMemo(() => (currentPage > 1 && (
        <span
            className={`${classNames.pageNumbersItemNext}`}
            onClick={handlePrevPageClick}
        >{labels.pagination.leftArrow}</span>
    )), [currentPage, handlePrevPageClick]);

    const memoizedNextButton = useMemo(() => (
        currentPage < pageNumbers.length && (
        <span
            className={`${classNames.pageNumbersItem}`}
            onClick={handleNextPageClick}
        >
            {labels.pagination.rightArrow}
        </span>
    )), [currentPage, pageNumbers, handleNextPageClick]);

    return ( <div className={classNames.pagination}>
        {memoizedPrevButton}
        {memoizedPageNumbers}
        {memoizedNextButton}
    </div>
);
}

export default Pagination;
