import { Button, Option, Dropdown, PositioningShorthand } from "@fluentui/react-components";
import { FiltersArray, StringLabel, TargetReleaseLabel } from "../common/constants";
import { CustomFilterProps, DropdownWrapperProps } from "../common/types";
import { classNames, getFilterStyle, getMinWidth, useStyles } from "./common.style";
import labels from "../common/FTE.string";
import { SyntheticEvent } from "react";

const DropdownWrapper = ({ checkedFilters, filtersList, onClickFilter, fKey, fLabel, isPresentationView, ...props }: DropdownWrapperProps) => {
    let checkedFilterList = (checkedFilters as { [key: string]: any })[fKey];
    const styles = useStyles();

    const handleOptionSelect = (ev: SyntheticEvent, data: string[]) => {
        onClickFilter(ev, data, fKey);     
    };

    
    return (
        <Dropdown
           aria-label={`${fLabel} filter`}
           disabled={isPresentationView && fKey === TargetReleaseLabel}
            multiselect
            listbox={{ className: styles.listbox }}
            button={<span style={getFilterStyle(checkedFilterList).filterArrayButton}>
                {fLabel}
            </span>}
            positioning={classNames.belowStart as PositioningShorthand}
            placeholder={fLabel}
            inlinePopup
            style={getMinWidth()}
            onOptionSelect={(ev, data) => handleOptionSelect(ev, data.selectedOptions)}
            selectedOptions={checkedFilterList}
            className={checkedFilterList.length > 0 ? fKey + ` ${labels.filter.selected}` : fKey}
        >
            {props.children}
        </Dropdown>
    );
};

const renderDropdownWrappers = ( 
    filters: any[],
    isPresentationView: boolean = false,
    checkedFilters: any,
    filtersList: any,
    onClickFilter: (ev: any, selectedOptions: any, fKey: string) => void
) => {
    return filters.map((f, i) => (
        <DropdownWrapper
            key={i}
            isPresentationView={isPresentationView}
            checkedFilters={checkedFilters}
            filtersList={filtersList}
            onClickFilter={onClickFilter}
            fKey={f.fKey}
            fLabel={f.fLabel}
        >
            {(filtersList as { [key: string]: any })[f.fKey].map((option: any) => (
                <Option key={option}>
                     {option}
                </Option>
            ))}
        </DropdownWrapper>
    ));
}

const Filters = ({ onClickFilter, filtersList, checkedFilters, clearAll, isPresentationView}: CustomFilterProps) => (
    <>
        <div style={getFilterStyle().filterGap} className={classNames.filterFields}>
            {renderDropdownWrappers(FiltersArray.slice(0, 4), isPresentationView, checkedFilters, filtersList, onClickFilter)}
        </div>
        <div style={getFilterStyle().filterMargin} className={classNames.filterFields}>
            {renderDropdownWrappers(FiltersArray.slice(4), isPresentationView, checkedFilters, filtersList, onClickFilter)}
            <Button onClick={clearAll} style={getFilterStyle().clearBtn} className={classNames.clrBtn}>{labels.filter.clearAll}</Button>
        </div>
    </>
);

export default Filters;