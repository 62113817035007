import React, { useMemo } from "react";
import { Dropdown, Option } from "@fluentui/react-components";
import { ColumnSelectorProps } from "../common/types";
import { classNames, getMinWidth } from "./common.style";
import StringConstant from "../common/FTE.string";
import { Positioning, StringLabel } from "../common/constants";

const ColumnSelector = ({
  targetReleaseFilters,
  onClickColumn,
  selectedColumns,
}: ColumnSelectorProps) => {
  const isDisabled = useMemo(() => {
    const length = selectedColumns?.length ?? 0;

    return (option: string) => {
      if (length < 4) return true; // Disable if length is less than 4
      if (length === 4) return selectedColumns.includes(option); // Disable selected column if length is 4
      if (length === 6) return !selectedColumns.includes(option); // Disable if option is not included and selected column length is 6
      return false; // Default case, not disabled
    };
  }, [selectedColumns]);

  return (
    <Dropdown
      aria-label={StringLabel.columnSelector}
      multiselect
      button={
        <span
          className={classNames.filterHeader}
          title={StringConstant.columnTitle}
        >
          {StringConstant.chooseColumns}
        </span>
      }
      positioning={Positioning}
      placeholder={StringConstant.chooseColumns}
      inlinePopup
      style={getMinWidth()}
      onOptionSelect={(ev, data) => onClickColumn(ev, data?.selectedOptions)}
      selectedOptions={selectedColumns}
    >
      {targetReleaseFilters?.map((option: string) => (
        <Option key={option} disabled={isDisabled(option)}>
          {option}
        </Option>
      ))}
    </Dropdown>
  );
};

export default ColumnSelector;