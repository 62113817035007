import React from 'react';
import logo from '../assets/mslogo.png';
import { Persona } from '@fluentui/react-components';
import labels from '../common/FTE.string';
import { classNames, getMainHeaderStyle } from './common.style';
import { Size } from '../common/constants';
 
interface MainHeaderProps {
    userName: string;
    loginName:string
}

const MainHeader: React.FC<MainHeaderProps> = ({ userName, loginName }) => {
    const { microsoftLogo } = labels;
    const { mainheader, mslogo, userName: userNameClass } = classNames;

    return (
        <>
        <div className={mainheader}>
            <div className={mslogo}>
                <img src={logo} alt={microsoftLogo}  />                    
            </div>
            <div className={userNameClass}>
            <Persona
                size={Size}
                name={userName}
                avatar={getMainHeaderStyle().avatar}
                secondaryText={loginName}                  
            />
            </div>
        </div>
        </>
    );
}

export default MainHeader;