import { Epic, Feature, RoadMapItem } from "../model";
import {
  Quarters,
  Regex,
  StringLabel,
  TargetReleaseLabel,
  statusColor
} from "./constants";
import StringConstant from "./FTE.string";
import { timeZoneAbbreviations } from "./timezone";
import { FilterTypes } from "./types";
import masterData from './master.json';

export const getFilterArrayByKeys = (
  RoadMaps: RoadMapItem[],
  keys: string[],
  currentFilter: string,
  filtersList: any
) => {
  const filters: FilterTypes = {
    investmentCategory: [],
    initiativeName: [],
    solutionArea: [],
    workloadScenario: [],
    engPillar: [],
    ai: [],
    changeType: [],
    targetRelease: []
  };
  try {
    keys?.forEach((key) => {
      if(key === StringLabel.InvestmentCategory) {
        filters[key as keyof FilterTypes] = Object.values(masterData?.data?.investmentCategory).sort((a, b) => a.localeCompare(b));
      } else if(key === StringLabel.InitiativeName) {
        filters[key as keyof FilterTypes] =  masterData?.data?.initiative.sort((a, b) => a.localeCompare(b));
      } else if (key === StringLabel.AI) {
        filters[key as keyof FilterTypes] =  masterData?.data?.ai.sort((a, b) => a.localeCompare(b));
      } else if (key === StringLabel.SolutionArea) {
        filters[key as keyof FilterTypes] =  masterData?.data?.solutionArea.sort((a, b) => {
          if (a === StringLabel.Na) return 1;
          if (b === StringLabel.Na) return -1;
          return a.localeCompare(b);
      });
      } else if (key === StringLabel.ChangeType) {
        filters[key as keyof FilterTypes] =  masterData?.data?.changeType.sort((a, b) => a.localeCompare(b));
      } else if (key === StringLabel.EngPillar) {
        filters[key as keyof FilterTypes] = [...Object.values(masterData.data.engPillar).sort((a, b) => a.localeCompare(b)), StringLabel.Na];
      } else if (key === StringLabel.WorkloadScenario) {
        filters[key as keyof FilterTypes] = masterData.data.workloadScenerio.sort((a, b) => a.localeCompare(b));
      } else if (key === TargetReleaseLabel) {
        filters[key] = getTargetRelease().sort();
      }
      if (currentFilter === key) {
        filters[key as keyof FilterTypes] = filtersList[key];
      }
    });

    return filters;
  } catch (error) {
    console.error(error);
    return filters;
  }
};

function agentHas(keyword: string) {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

export function isEdge() {
  return agentHas("Edg");
}

export function getLastTwoDigitsOfYear(): number {
  const date = new Date();
  const fullYear = date.getFullYear();
  const month = date.getMonth();
  const fiscalYearStartMonth = 6;
  let fiscalYear = fullYear + 1;
  if (month < fiscalYearStartMonth) {
    fiscalYear = fullYear - 1;
  }

  const lastTwoDigits = fiscalYear % 100;
  return lastTwoDigits;
}

export const getTargetRelease = () => {
  const currentYear = getLastTwoDigitsOfYear();
  const quarterOptions = [];
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < Quarters.length; j++) {
      const quarter = Quarters[j];
      quarterOptions.push(`FY${currentYear + i}/${quarter}`);
    }
  }
  return quarterOptions;
}

export function containsSearchString(
  item: any,
  searchString: string,
  wholeWordMatch: boolean
): boolean {
  try {
    if (searchString !== StringLabel.EmptyString) {
      if (typeof item === "object" && item !== null) {
        return Object.values(item).some((value) => {
          value = value?.toString();
          if (typeof value === "string") {
            if (wholeWordMatch) {
              let newSearchText = searchString;
              if (searchString?.includes(StringLabel.SquareBracket)) {
                newSearchText = searchString?.replace(Regex.ReplaceSearchRegex, StringLabel.ReplaceSearchString);
              }

              return new RegExp(
                `${Regex.newRegexPrefix}${newSearchText?.trim().toLowerCase()}${Regex.newRegexPrefix}`
              ).test(value?.toLowerCase());
            }
            return value
              .toLowerCase()
              .includes(searchString?.trim().toLowerCase());
          }
        });
      }
    }
  } catch {
    return false;
  }
  return false;
}

export function filterFeatures(
  features: Feature[],
  searchString: string,
  wholeWordMatch: boolean
): Feature[] {
  return features.filter((feature) =>
    containsSearchString(feature, searchString, wholeWordMatch)
  );
}

export function filterEpics(
  epics: Epic[],
  searchString: string,
  wholeWordMatch: boolean
): Epic[] {
  return epics
    .map((epic) => {
      const matchedEpic = containsSearchString(
        epic,
        searchString,
        wholeWordMatch
      );

      return {
        ...epic,
        features: matchedEpic
          ? epic?.features
          : filterFeatures(epic?.features, searchString, wholeWordMatch),
      };
    })
    .filter(
      (epic) =>
        epic?.features?.length > 0 ||
        containsSearchString(epic, searchString, wholeWordMatch)
    );
}

export function filterRoadMapItems(
  records: RoadMapItem[],
  searchString: string,
  wholeWordMatch: boolean
): RoadMapItem[] {
  return records
    .map((record) => {
      const matchedInitiative = containsSearchString(
        record,
        searchString,
        wholeWordMatch
      );

      return {
        ...record,
        epics: matchedInitiative
          ? record?.epics
          : filterEpics(record?.epics, searchString, wholeWordMatch),
      };
    })
    .filter(
      (record) =>
        record?.epics?.length > 0 ||
        containsSearchString(record, searchString, wholeWordMatch)
    );
}


export const filterEpicData = (
  roadMapItems: RoadMapItem[],
  checkedFilters: any,
  key: string
) => {
  if (checkedFilters[key].length === 0) return roadMapItems;

  return roadMapItems
    .map((item) => ({
      ...item,
      epics: item?.epics?.filter((epic: any) =>
        epic[key]
          .split(StringConstant.modernUi.semiColon)
          .map((epic: any) => epic?.trim())
          .some((filter: any) => checkedFilters[key]?.includes(filter))
      ),
    }))
    .filter((item) => item?.epics?.length > 0);
};

export const filterFeatureData = (
  roadMapItems: RoadMapItem[],
  checkedFilters: any,
  key: string
) => {
  if (checkedFilters[key].length === 0) return roadMapItems;
  if(key === TargetReleaseLabel) {
// Map and filter the roadmap items
    return roadMapItems
      ?.map((item) => ({
        ...item,
        epics: item?.epics
          ?.map((epic) => ({
            ...epic,
            features: epic?.features?.filter((feature) => {
              const yearQuarter = getFormatedQuarter(feature);
              return checkedFilters[key]?.includes(yearQuarter);
            }),
          }))
          .filter((epic) => epic?.features?.length > 0),
      }))
      .filter((item) => item?.epics?.length > 0);
  }

  if(key === StringLabel.AI) {
    return roadMapItems
    ?.map((item) => ({
      ...item,
      epics: [...item?.epics
        ?.map((epic) => ({
          ...epic,
          features: epic?.features?.filter((feature: any) =>
            feature[key]
              .split(StringConstant.modernUi.semiColon)
              .map((featureItem: any) => featureItem?.trim())
              .some((featureItem: any) =>
                checkedFilters[key]?.includes(featureItem)
              )
          ),
        }))
        ?.filter((epic) => epic.features.length > 0), ...item.epics.filter(epic =>
          epic.ai
              .split(StringConstant.modernUi.semiColon)
              .map((epicItem: any) => epicItem?.trim())
              .some((epicItem: any) =>
                checkedFilters[key]?.includes(epicItem)
              )
        )]
    }))
    ?.filter((item) => item.epics.length > 0);
  }
  
  return roadMapItems
    ?.map((item) => ({
      ...item,
      epics: item?.epics
        ?.map((epic) => ({
          ...epic,
          features: epic?.features?.filter((feature: any) =>
            feature[key]
              .split(StringConstant.modernUi.semiColon)
              .map((featureItem: any) => featureItem?.trim())
              .some((featureItem: any) =>
                key === StringLabel.WorkloadScenario ? (checkedFilters[key]?.includes(featureItem) || featureItem === StringLabel.allWorkload) :  checkedFilters[key]?.includes(featureItem)
              )
          ),
        }))
        ?.filter((epic) => epic.features.length > 0),
    }))
    ?.filter((item) => item.epics.length > 0);
};

export const sortRoadMapItems = (filterItems: RoadMapItem[], SortingOrder: any) => {
  if (SortingOrder.length > 0 && SortingOrder === StringLabel.SortingOrder) {
    return filterItems
      .map((item: RoadMapItem) => {
        return {
          ...item,
          epics: item?.epics?.sort((a, b) =>
            a?.roadmapItemName?.localeCompare(b?.roadmapItemName, StringLabel.EnglishLocale, {
              sensitivity: StringLabel.Base as "base",
            })
          ),
        };
      })
      .sort((a, b) =>
        a?.initiativeName?.localeCompare(b.initiativeName, StringLabel.EnglishLocale, {
          sensitivity: StringLabel.Base as "base",
        })
      );
  }

  if (SortingOrder.length > 0 && SortingOrder === StringLabel.ReverseSortingOrder) {
    return filterItems
      ?.map((item: RoadMapItem) => {
        return {
          ...item,
          epics: item?.epics?.sort((a, b) =>
            b?.roadmapItemName?.localeCompare(a?.roadmapItemName, StringLabel.EnglishLocale, {
              sensitivity: StringLabel.Base as "base",
            })
          ),
        };
      })
      .sort((a, b) =>
        b?.initiativeName?.localeCompare(a?.initiativeName, StringLabel.EnglishLocale, {
          sensitivity: StringLabel.Base as "base",
        })
      );
  }
}

export const getFormatedQuarter = (feature: Feature) => {
  return feature?.financialQuarter ? `${feature?.financialYear}/${feature?.financialQuarter}` : feature?.financialYear;
}

export const getFormatedDate = (date: string) => {
  const utcDate = new Date(date);
  let formatedDate: Intl.DateTimeFormat;
  let formattedDate = StringLabel.EmptyString;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!isNaN(utcDate.getTime())) {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: timeZone,
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true // 12-hour format
    };
    formatedDate = new Intl.DateTimeFormat(Intl.DateTimeFormat().resolvedOptions().locale, options);
    const parts = formatedDate.formatToParts(utcDate) ;

    // Extract date and time components
    const day = parts.find(p => p.type === StringLabel.Day)?.value || StringLabel.EmptyString;
    const month = parts.find(p => p.type === StringLabel.Month)?.value || StringLabel.EmptyString;
    const year = parts.find(p => p.type === StringLabel.Year)?.value || StringLabel.EmptyString;
    const hour = parts.find(p => p.type === StringLabel.Hour)?.value || StringLabel.EmptyString;
    const minute = parts.find(p => p.type === StringLabel.Minute)?.value || StringLabel.EmptyString;
    const period = parts.find(p => p.type === StringLabel.dayPeriod)?.value || StringLabel.EmptyString;
    const timezoneAbbreviation = timeZoneAbbreviations[timeZone as keyof typeof timeZoneAbbreviations] || timeZone;
    formattedDate = `${day}-${(month.charAt(0).toUpperCase() + month.slice(1)).substring(0,3)}-${year} ${hour}:${minute} ${period.toUpperCase()} ${timezoneAbbreviation}`;
  }
  return `${formattedDate}`;
}

export const getClassName = (state: string) => {
  return statusColor[state as keyof typeof statusColor];
}

export const setColumn = () => {

  const allColumns = getTargetRelease().sort();

  const currentYear = getLastTwoDigitsOfYear();
  let currentYearColumns = allColumns?.filter((a) =>
    a.includes(currentYear?.toString()) && Quarters.includes(a.split("/")[1])
  );

  const otherColumns = allColumns
  ?.filter((col) => !currentYearColumns?.includes(col))
  .sort();

if (currentYearColumns.length < 4) {
  for (let i = 0; i < otherColumns.length; i++) {
    if (currentYearColumns.length >= 4) {
      break;
    }
    currentYearColumns.push(otherColumns[i]);
  }
} else {
  currentYearColumns = currentYearColumns.slice(0, 4);
}

  return {
    allColumns: allColumns,
    columns: currentYearColumns,
  };
}

export const replaceValue = (value: string, enumObj: any): string => {
  return enumObj[value as keyof typeof enumObj] || value;
}; 

export const replaceValuesWithEnum = (values: string, enumObj: any): string => {
  return values
      .split(StringConstant.modernUi.semiColon) 
      .map(value => value.trim()) 
      .map(value => replaceValue(value, enumObj)) 
      .join(StringConstant.modernUi.semiColon);
};

export const removeHtmlTagsAndEntities = (values: string): string => {
  
  // Remove HTML tags
  let cleanedString = values.replace(Regex.HtmlTagRegex, StringLabel.EmptyString);

  // Replace HTML entities with their corresponding characters
  cleanedString = cleanedString
      .replace(Regex.SpaceRegex, StringConstant.accordianWrapper.space )  // Non-breaking space
      .replace(Regex.QuestionMarkRegex, StringLabel.QuestionMark)  // Quotation mark
      .replace(Regex.AmpersandRegex, StringLabel.Ampersand)   // Ampersand
      .replace(Regex.LessThanRegex, StringLabel.LessThan)    // Less-than sign
      .replace(Regex.GreaterThanRegex, StringLabel.GreaterThan);   // Greater-than sign

  return cleanedString;
};

export const  transformFeatureArray: (features: Feature[]) => any = (features: Feature[]) => {
  const featuresMap = new Map();

  features.forEach(feature => {
    const key = `${feature.financialYear}-${feature.financialQuarter || 'NoYear'}`;

    if (!featuresMap.has(key)) {
      featuresMap.set(key, {
        financialYear: feature.financialYear,
        financialQuarter: feature.financialQuarter || StringLabel.EmptyString,
        details: []
      });
    }

    featuresMap.get(key).details.push({
      ...feature
      
    });
  });
  return Array.from(featuresMap.values());
}

export const filterArrayByStatus = (filteredData: RoadMapItem[], checkedStatusLabels: any[]) => {
  return filteredData
  ?.map((item) => ({
    ...item,
    epics: item?.epics
      ?.map((epic) => ({
        ...epic,
        features: epic?.features?.filter((feature) => {
          
          return checkedStatusLabels?.includes(feature?.state);
        }),
      }))
      .filter((epic) => epic?.features?.length > 0),
  }))
  .filter((item) => item?.epics?.length > 0)
}

export const getstatusCount = (allFeature: Feature[], statusList: any) => {
  return allFeature?.filter((feature: Feature) => statusList?.includes(feature?.state))?.length;
}

export const currentYearQuarterDetails = (feature: Feature) => {
  const currentYear = getLastTwoDigitsOfYear();
  const yearQuarter = getFormatedQuarter(feature);
  return yearQuarter.includes(currentYear?.toString()) && Quarters.includes(yearQuarter.split("/")[1]);
}

export const localTime = () => {
  const pacificTime = new Date('2024-10-09T04:00:00-07:00'); 
  const localTime = pacificTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneAbbreviation = timeZoneAbbreviations[timeZone as keyof typeof timeZoneAbbreviations] || timeZone;
  return `${localTime.toUpperCase()} ${timezoneAbbreviation}`;
}

