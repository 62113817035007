import { useMemo, useCallback } from "react";
import { Dropdown, Option, OptionOnSelectData, SelectionEvents } from "@fluentui/react-combobox";
import { StringLabel } from "../common/constants";
import labels from "../common/FTE.string";
import { classNames } from "./common.style";

const Sort = ({ sortingOrder, onSort }: { sortingOrder: string, onSort: (value: string) => void }) => {
    const handleOptionSelect = useCallback(
        (event: SelectionEvents, data: OptionOnSelectData) => {
            onSort(data?.optionValue || StringLabel.SortingOrder);
        },
        [onSort]
    );

    return useMemo(
        () => (
            <div className={classNames.allignSort}>
                <Dropdown
                    aria-label={StringLabel.sortby}
                    onOptionSelect={handleOptionSelect}
                    placeholder={labels.SortBy}
                    selectedOptions={[sortingOrder]}
                    className={classNames.sortWidth}
                >
                    <Option key={labels.Az} text={StringLabel.SortingOrder} value={StringLabel.SortingOrder} defaultChecked>
                        {StringLabel.SortingOrder}
                    </Option>
                    <Option key={labels.Za} text={StringLabel.ReverseSortingOrder} value={StringLabel.ReverseSortingOrder}>
                        {StringLabel.ReverseSortingOrder}
                    </Option>
                </Dropdown>
            </div>
        ),
        [sortingOrder, handleOptionSelect]
    );
};

export default Sort;