import React, { SyntheticEvent, useCallback } from "react";
import { Checkbox } from "@fluentui/react-components";
import { StatusFilter, StatusFilterProps } from "../common/types";
import { getstatusCount } from "../common/utils";
import { classNames, getStatusFilterStyle } from "./common.style";
import labels from "../common/FTE.string";

const StatusFilterComponent = ({
  allStatus,
  allFeatures,
  onStatusChange,
}: StatusFilterProps) => {

  const handleStatusChange = useCallback((ev: SyntheticEvent, key: StatusFilter) => {
    onStatusChange(ev, key);
  }, [onStatusChange]);

  return (
    <>
      <hr/>
      <div className={classNames.statusContainer}>
        {allStatus.map((key, index) => (
          <div className={classNames.statusItem} key = {`staus-${index}`}>
            <div className={classNames.statusAlign}>
              <Checkbox
                aria-label={`${key.status} checkbox`}
                style={getStatusFilterStyle(key).checkBoxStyle}
                className={classNames.checkBoxStatus}
                checked={key.checked}
                onChange={(ev) => handleStatusChange(ev, key)}
              />
              <div className={classNames.statusBox} style={getStatusFilterStyle(key).statusBoxStyle}></div>
              <span className={classNames.statusCount}>
                {key.status ? getstatusCount(allFeatures, key.statusList) : labels.emptyString}
              </span>
              <span className={classNames.label}>{key.status || labels.emptyString}</span>
            </div>
          </div>
        ))}
      </div>
      <hr/>
    </>
  );
};

export default StatusFilterComponent;