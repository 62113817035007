import React, { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import Home from './Home';

const Auth: React.FC = () => {
  const { accounts, inProgress, instance } = useMsal();

  useEffect(() => {
    const handleLoginRedirect = async () => {
      try {
        if (accounts.length === 0 && inProgress === InteractionStatus.None) {
          await instance.loginRedirect();
        }
      } catch (error) {
        console.error("Login redirect failed: ", error);
      }
    };

    handleLoginRedirect();
  }, [accounts, inProgress, instance]);

  if (accounts.length > 0) {
    return <Home />;
  }

  return null;
};

export default Auth;