const StringConstant = {
    All: "All",
    emptyString: "",
    LastUpdated: "Last refreshed: ",
    NextRefreshDate: "Daily data refresh runs at ",
    searchString: "Search for a specific item:",
    wholeWordMatch: "Match whole word",
    filterLabel: "Filter the items below:",
    presentationView: "Presentation View",
    initiativeNameHeading: "Initiative Name - Epic Name",
    noDataFound: "No data found",
    chooseTheme: "Choose theme",
    microsoftLogo: "Microsoft Logo",
    SortBy: "Sort By",
    Az: "AZ",
    Za: "ZA",
    switchDetailView: "Switch to Detail View",
    switchPresentationView: "Switch to Presentation View",
    search: "Search",
    hash: "#",
    chooseColumns: "Choose columns",
    columnTitle: "Minimum of 4 columns must be selected",
    statusFilterTitle: "ADO Feature State Legend:",
    featureInfo: "Feature Information",
    note: "NOTE: Contents of this Roadmap are Microsoft Confidential and for Internal Use Only.",
    
    header: {
        fastTrackEngineering: "FastTrack Engineering Roadmap",
        latestUpdate: " Get the latest updates from the FT Engineering team on the deployment automation, AI, and transformational investments that are helping simplify deployment journeys, accelerate usage, and drive revenue.",
        roadMapButton: "Using this roadmap",
    },
    accordianWrapper: {
        investmentCategories: "Investment Category",
        initiativeName: "Initiative Name",
        solutionArea: "Solution Area",
        aiFlag: "AI Flag",
        changeType: "Change Type",
        corePriority: "Core Priority",
        scope: "Scope",
        descriptor: "Descriptor",
        engPillar: "Engineering Pillar",
        workload: "Workload",
        space: " ",
        targetRelease: "Target Release",
        all: "All",
    },
    modernUi: {
        fastTrackEngineering: "FASTTRACK ENGINEERING",
        roadMapItem: "Roadmap Item",
        hiphen: " - ",
        colon: ": ",
        initiative: "Initiative",
        epic: "Epic",
        feature: "Feature",
        semiColon: ";",
    },
    filter: {
        selected: "selected",
        clearAll: "Clear all"
    },
    pagination: {
        leftArrow: "<",
        rightArrow: ">",
    }
};

export default StringConstant;