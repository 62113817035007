import React from "react";
import labels from "../common/FTE.string";
import { getClassName, getFormatedQuarter, removeHtmlTagsAndEntities } from "../common/utils";
import { classNames } from "./common.style";
import { RoadmapURL, StringLabel } from "../common/constants";

const FeatureDetails = ({ feature }: { feature: any }) => {
    const quarterTitle = getFormatedQuarter(feature);
    return (
      <div>
        <strong>{`${quarterTitle}${labels.modernUi.colon}`}</strong>
        {feature.details.map((detail: any, index: any) => (
          <React.Fragment key={index}>
            <div className={classNames.epicMargin}>
              <span className={`${classNames.circle} ${classNames.statusMargin}`}>
                <span
                  className={`${classNames.square}`}
                  style={{ backgroundColor: getClassName(detail.state) }}
                ></span>
              </span>
              <strong>{labels.modernUi.feature}{labels.modernUi.colon}</strong>
              <a href={`${RoadmapURL}${detail?.id}`} target={StringLabel.Blank} aria-label={`Feature link ${detail.id}`}>
                {detail?.id}
              </a>
              {labels.accordianWrapper.space}
              {detail?.scope}
            </div>
            <div className={classNames.featureMargin}>
              <strong>{labels.accordianWrapper.descriptor}{labels.modernUi.colon}</strong>
              {removeHtmlTagsAndEntities(detail?.descriptor)}
            </div>
            <div className={classNames.featureMargin}>
              <strong>{labels.accordianWrapper.engPillar}{labels.modernUi.colon}</strong>
              {detail?.engPillar}
            </div>
            <div className={classNames.featureMargin}>
              <strong>{labels.accordianWrapper.workload}{labels.modernUi.colon}</strong>
              {detail?.workloadScenario.replaceAll(";", "; ")}
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  };

  export default FeatureDetails;