import { RoadmapURL, StringLabel } from "../common/constants";
import labels from "../common/FTE.string";
import { classNames } from "./common.style";

const EpicDetails = ({ epic, item }: { epic: any; item: any }) => (
    <div className={classNames.accordianSection}>
      <div>
        <strong>{labels.accordianWrapper.investmentCategories}{labels.modernUi.colon}</strong>
        {item?.investmentCategory}
      </div>
      <div>
        <strong>{labels.accordianWrapper.initiativeName}{labels.modernUi.colon}</strong>
        <a href={`${RoadmapURL}${epic?.initiativeId}`} target={StringLabel.Blank} aria-label={`Initiative link ${epic.initiativeId}`}>
          {epic?.initiativeId}
        </a>
        {labels.accordianWrapper.space}
        {item?.initiativeName}
      </div>
      <div>
        <strong>{labels.accordianWrapper.solutionArea}{labels.modernUi.colon}</strong>
        {epic?.solutionArea.replaceAll(labels.modernUi.semiColon, labels.modernUi.semiColon + labels.accordianWrapper.space)}
      </div>
      <div>
        <strong>{labels.accordianWrapper.aiFlag}{labels.modernUi.colon}</strong>
        {epic?.ai.replaceAll(labels.modernUi.semiColon, labels.modernUi.semiColon + labels.accordianWrapper.space)}
      </div>
      <div>
        <strong>{labels.accordianWrapper.changeType}{labels.modernUi.colon}</strong>
        {epic?.changeType}
      </div>
      <div>
        <strong>{labels.accordianWrapper.corePriority}{labels.modernUi.colon}</strong>
        {epic?.corePriority}
      </div>
    </div>
  );

  export default EpicDetails;