import { PositioningShorthand } from "@fluentui/react-components";

export const FiltersArray: { fKey: string, fLabel: string }[] = [{
    fKey: "investmentCategory",
    fLabel: "Investment Category"
},
{
    fKey: "initiativeName",
    fLabel: "Initiative Name"
},
{
    fKey: "solutionArea",
    fLabel: "Solution Area"
},
{
    fKey: "workloadScenario",
    fLabel: "Workload"
},
{
    fKey: "engPillar",
    fLabel: "Engineering Pillar"
},
{
    fKey: "ai",
    fLabel: "ADO Tags"
},
{
    fKey: "changeType",
    fLabel: "Change Type"
},
{
    fKey: "targetRelease",
    fLabel: "Target Release"
}]

export const DefaultFilters = {
    investmentCategory: [],
    initiativeName: [],
    solutionArea: [],
    workloadScenario: [],
    engPillar: [],
    ai: [],
    changeType: [],
    targetRelease: [],
};

export const Themes = {
    Primary: {
        mainHeader: "#243a5e",
        header: "#0078d4",
        roadmapItem: "#0078d433",
        targetRelease: "#f2f2f2",
    },
    Secondary: {
        mainHeader: "#243a5e",
        header: "#008575",
        roadmapItem: "#00857533",
        targetRelease: "#f2f2f2",
    },
    Tertiary: {
        mainHeader: "#243a5e",
        header: "#7030A0",
        roadmapItem: "#7030a033",
        targetRelease: "#f2f2f2",
    },
}


export enum statusColor {
    Backlog = "#818181",
    Funnel = "#92d050",
    "Portfolio Backlog" = Backlog,
    Analyzing = "#007466",
    Implementing = "#0078d4",
    "Pre PI Review" = "#ffb900",
    Done = "#5bd4ff",
    "Validating on Stage" = "#d148d4",
    "Deploying to Production" = "#243a5e",
    Releasing = "#8661c5"
}

export const AllStatus = [
    {
        status: "Funnel",
        checked: false,
        statusList: ["Funnel"]
    },
    {
        status: "Analyzing",
        checked: false,
        statusList: ["Analyzing"]
    },
    {
        status: "Pre PI Review",
        checked: false,
        statusList: ["Pre PI Review"]
    },
    {
        status: "Backlog",
        checked: false,
        statusList: ["Backlog", "Portfolio Backlog"]
    },
    {
        status: "Implementing",
        checked: false,
        statusList: ["Implementing"]
    },
    {
        status: "Validating on Stage",
        checked: false,
        statusList: ["Validating on Stage"]
    },
    {
        status: "Deploying to Production",
        checked: false,
        statusList: ["Deploying to Production"]
    },
    {
        status: "Releasing",
        checked: false,
        statusList: ["Releasing"]
    },
    {
        status: "Done",
        checked: false,
        statusList: ["Done"]
    }
]

export const SortedChangeType = ["New", "Expand", "Maintain", "Reduce"];
export const HeaderVideoLink = "https://aka.ms/FTEngRoadmapVideo";
export const featureInfoLink = "https://aka.ms/featurestate";
export const InitiativeFilters = ["investmentCategory", "initiativeName"];
export const EpicFilters = ["solutionArea", "changeType"];
export const FeatureFilters = ["engPillar", "ai", "workloadScenario", "targetRelease"];
export const TargetReleaseLabel = "targetRelease";
export const RoadmapURL = "https://dev.azure.com/O365fasttrack/FastTrack/_workitems/edit/";
export const Quarters = ["Q1", "Q2", "Q3", "Q4"];
export const Positioning: PositioningShorthand = "below-start";
export const Size = "medium";
export const StringLabel = {
    EmptyString: "",
    SortingOrder: "A-Z",
    ReverseSortingOrder: "Z-A",
    Primary: "Primary",
    ItemsPerPage: "20",
    FirstPage: "1",
    Blank: "_blank",
    removed: "Removed",
    sortby: "Sort by",
    columnSelector: "Select Columns",
    QuestionMark: '"',
    Ampersand: "&",
    LessThan: "<",
    GreaterThan: ">",
    Day: 'day',
    Month: 'month',
    Year: 'year',
    Hour: 'hour',
    Minute: 'minute',
    UTCDateConstantZ: 'z',
    EnglishLocale: 'es',
    Base: 'base',
    ReplaceSearchString: "!",
    SquareBracket: "[",
    dayPeriod: "dayPeriod",
    Na: "N/A",
    SelectAll: "Select All",
    InvestmentCategory: "investmentCategory",
    InitiativeName: "initiativeName",
    SolutionArea: "solutionArea",
    AI: "ai",
    EngPillar: "engPillar",
    ChangeType: "changeType",
    WorkloadScenario: "workloadScenario",
    allWorkload: "All Workloads",
}

export const Regex = {
    SpaceRegex: /&nbsp;/g,
    QuestionMarkRegex: /&quot;/g,
    AmpersandRegex: /&amp;/g,
    LessThanRegex: /&lt;/g,
    GreaterThanRegex: /&gt;/g,
    HtmlTagRegex: /<\/?[^>]+>/g,
    ReplaceSearchRegex: /\[/g,
    newRegexPrefix: "\\b",
}