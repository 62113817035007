import { Button, Link } from "@fluentui/react-components";
import { HeaderVideoLink, StringLabel } from "../common/constants";
import labels from '../common/FTE.string';
import { classNames } from "./common.style";

const Header = () => {
    const { fastTrackEngineering, latestUpdate, roadMapButton } = labels.header;
    const { header, roadmapHeader, noteHeader, noteFont } = classNames;

    return <header className={header}>
        <h1>{fastTrackEngineering}</h1>
        <h6>
            {latestUpdate}
        </h6>
        <div className={noteHeader}>
            <Link href={HeaderVideoLink} target={StringLabel.Blank}>
                <Button appearance="primary" className={roadmapHeader}>
                    {roadMapButton}
                </Button>
            </Link>
            <span className={noteFont}>{labels.note}</span>
        </div>
    </header>
}

export default Header;
